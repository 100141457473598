<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      @click:outside="dialog = false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn class="newStyle" :ripple="false" v-bind="attrs" v-on="on" text >Μάθε περισσότερα</v-btn>

      </template>
      <v-card>
        <v-card-title>
          <span class="text-h5">Τεχνολογικά προηγμένα καύσιμα <br> D-FORCE της AEGEAN</span>
        </v-card-title>
        <hr>
            <v-card-text>
             <p> H <b>AEGEAN</b> , ανταποκρινόμενη στις διαρκώς μεταβαλλόμενες ανάγκες των καταναλωτών, ανέπτυξε νέα προϊόντα <b>ντίζελ κίνησης</b> και <b>αμόλυβδης βενζίνης</b>,

              τα οποία είναι εμπλουτισμένα με τα πλέον προηγμένα βελτιωτικά πρόσθετα και η σύνθεσή τους εγγυάται για τον κινητήρα:  </p>
                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> τη <strong>μέγιστη απόδοσή</strong> του </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> τη <strong>βελτίωση της αντοχής</strong> του στο χρόνο </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> την <strong>μείωση του κόστους συντήρησης</strong> </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' />  την <strong>μείωση του θορύβου </strong> και των <strong>κραδασμών μέχρι και 20% </strong> </p>

              <p>Το νέο πανίσχυρο <strong> D-FORCE ντίζελ κίνησης </strong> : </p>

                  <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> έχει αυξημένο αριθμό κετανίων (μέχρι και 3 μονάδες) </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> μειώνει άμεσα την κατανάλωση καυσίμου μέχρι και 3% </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> καθαρίζει τα μπεκ από τις επικαθήσεις και τα διατηρεί καθαρά </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> εξασφαλίζει καλύτερη ανάφλεξη και καύση </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> προστατεύει το σύστημα τροφοδοσίας από τη διάβρωση </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> μειώνει τις εκπομπές καυσαερίων </p>

              <p>Η συνεχής χρήση της νέας <b>D-FORCE αμόλυβδης βενζίνης</b> : </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> μειώνει την κατανάλωση καυσίμου μέχρι και 3% </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> αποτρέπει τις εναποθέσεις στις βαλβίδες και την πολλαπλή εισαγωγής </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> παρέχει αποτελεσματικό καθαρισμό των υφιστάμενων αποθέσεων στις βαλβίδες (clean up) </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> διατηρεί όλο το σύστημα τροφοδοσίας καυσίμου καθαρό </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> συντελεί στην σημαντική μείωση τριβής </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> βελτιώνει την οδηγική συμπεριφορά του οχήματος (καλύτερη εκκίνηση με ψυχρό κινητήρα και ομαλότερη λειτουργία του κινητήρα) </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> προστατεύει το σύστημα καυσίμων από διάβρωση και σχηματισμό σκουριάς </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> μειώνει το κόστος συντήρησης του οχήματος </p>

              <p><b>Μεγάλη προσφορά </b> στα τεχνολογικά προηγμένα καύσιμα <b>D-FORCE </b> της <b>AEGEAN !</b></p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> κάθε <b>ΔΕΥΤΕΡΑ</b> η νέα <b>D-FORCE UNLEADED</b> στην τιμή της απλής αμόλυβδης βενζίνης </p>

                <p><img style="display: inline;width: 25px" src='@/assets/images/logo/margaret.png' /> κάθε <b>ΤΕΤΑΡΤΗ</b> το πανίσχυρο <b>D-FORCE DIESEL</b> στην τιμή του απλού πετρελαίου κίνησης </p>

              <p><b>Διπλασιάστε</b> τους πόντους σας επιλέγοντας τα <b>D-FORCE</b>  καύσιμα της <b>AEGEAN.</b> </p>

             <p> *Διαθέσιμα σε <b>επιλεγμένα πρατήρια AEGEAN</b> σε όλη την Ελλάδα.</p>
            </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="newStyle"
            color="white darken-1"
            text
            @click="dialog = false"
          >
            Κλείσιμο
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

    export default {
       name: 'LearnMore',
       data() {
           return  {
             dialog: false
           }
       }
    };
</script>
<style lang="scss" scoped>
.v-btn.newStyle {
    background-color: #004a91;
    color: #fff;
    font-size: 1rem;
    font-weight: 400;
    padding: 0.8rem 2rem 2rem 2rem;
    border: 2px solid #004a91;
    position: relative;
    margin-top: 15px;
}
.text-h5{
  color: #222;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: -0.025rem;
}
</style>