<template>
  <!-- Start Slider Area  -->
  <div class="rn-slider-area">
    <!-- Start Single Slide  -->
    <div class="mt--100">
      <v-container class="position-relative">
        <!-- Start Service Area  -->
        <div class="service-wrapper service-white">
          <ServiceTwo />
        </div>
        <!-- End Service Area  -->
      </v-container>
    </div>
    <!-- End Single Slide  -->
  </div>
  <!-- End Slider Area  -->
</template>

<script>
import ServiceTwo from "@/components/service/ServiceTwo";
export default {
  components: {
    ServiceTwo,
  },
};
</script>
