<template>
    <v-row>
        <v-col xl="6"
               lg="6"
               md="6"
               sm="12"
               cols="12">
            <div data-aos="fade-right" data-aos-duration="1200" class="mb--100">
                <img src="@/assets/images/blog/diesel.png" alt="Creative Agency" />
                <img src="@/assets/images/blog/unleaded.png" alt="Creative Agency" />
                
            </div>
        </v-col>
       <v-col xl="5"
               lg="5"
               md="5"
               sm="12"
               cols="12">
               <div data-aos="fade-left" data-aos-duration="1200">
                  <h3 class="boldHeader">Τεχνολογικά προηγμένα καύσιμα D-FORCE της AEGEAN</h3>
                  <p>μέγιστη φροντίδα του κινητήρα σας, διπλάσιοι πόντοι επιβράβευσης και μοναδικές προσφορές! Εφοδιαστείτε κάθε ΔΕΥΤΕΡΑ με την νέα D-FORCE UNLEADED στην τιμή της απλής αμόλυβδης βενζίνης και κάθε ΤΕΤΑΡΤΗ με το πανίσχυρο D-FORCE DIESEL στην τιμή του απλού πετρελαίου κίνησης και επωφεληθείτε διπλά!</p>
               </div>
                <LearnMore/>
        </v-col>       
    </v-row>
</template>

<script>
  import axios from 'axios';
  import LearnMore from "@/views/LearnMore";

  export default {
    name: "ServiceThree",
    components: {
      LearnMore,
    },
    data() {
      return {
      };
    },
  };
</script>
<style scoped>
.boldHeader{
  color: #222;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  letter-spacing: -0.025rem;
  font-size: 1.425rem;
}
</style>

