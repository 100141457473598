<template>
  <!-- Start Slider Area  -->
  <div class="rn-slider-area">
    <!-- Start Single Slide  -->
    <div class="mt--100">
      <v-container class="position-relative">
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title theme-gradient">

              </h1>
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <!-- Start Service Area  -->
        <div class="service-wrapper service-white">
          <ServiceThree />
        </div>
        <!-- End Service Area  -->
      </v-container>
    </div>
    <!-- End Single Slide  -->
  </div>
  <!-- End Slider Area  -->
</template>

<script>
import ServiceThree from "@/components/service/ServiceThree";
export default {
  components: {
    ServiceThree,
  },
};
</script>
