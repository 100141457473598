<template>
  <!-- Start Slider Area  -->
  <div class="rn-slider-area">
    <!-- Start Single Slide  -->
    <div
      class="slide slide-style-1 slider-fixed--height d-flex align-center bg_image bg_light_blue bg_image22"
    >
      <v-container class="position-relative">
        <v-row>
          <v-col cols="12">
            <div class="inner">
              <h1 class="heading-title theme-gradient">

              </h1>
            </div>
          </v-col>
        </v-row>
        <!-- End .row -->
        <!-- Start Service Area  -->
        <div class="service-wrapper service-white">
          <ServiceOne />
        </div>
        <!-- End Service Area  -->
      </v-container>
    </div>
    <!-- End Single Slide  -->
  </div>
  <!-- End Slider Area  -->
</template>

<script>
import ServiceOne from "@/components/service/ServiceOne";
export default {
  components: {
    ServiceOne,
  },
};
</script>
<style lang="scss">
  .bg_image22 {
    background-image: url('../../assets/images/blog/background.jpg') !important;
    background-size: cover !important;
  }
  @media only screen and (max-width: 769px) {
    .bg_image22 {
      background-image: url('../../assets/images/blog/background_small.jpg') !important;
    }
  }
</style>
