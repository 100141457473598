<template>
    <div>
        <!-- Start Header Area -->
        <Header>
            <img slot="logo" src="@/assets/images/logo/logo.png" width="173" />
        </Header>
        <!-- End Header Area -->
        <!-- Start Slider Area -->
        <div class="slider-wrapper">
            <SliderOne></SliderOne>
        </div>
        <div class="slider-wrapper">
            <SliderTwo></SliderTwo>
        </div>
        <!-- End Slider Area -->
        <div class="slider-wrapper">
            <SliderThree></SliderThree>
        </div>
        <div class="slider-wrapper">
            <SliderFour></SliderFour>
        </div>
        <!-- Start Footer Area  -->
        <Footer />
        <!-- End Footer Area  -->
    </div>
</template>

<script>
  import Header from "@/components/header/HeaderIndex";
  import SliderOne from "@/components/slider/SliderOne";
  import SliderTwo from "@/components/slider/SliderTwo";
  import SliderThree from "@/components/slider/SliderThree";
  import SliderFour from "@/components/slider/SliderFour";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios';
  import Register from "@/views/Register";


  export default {
    components: {
      Register,
      Header,
      SliderOne,
      SliderTwo,
      SliderThree,
      SliderFour,
      Footer,
    },
    data() {
      return {};
    },
  };
</script>
<style scoped>
.button_beautify {
    padding: 30px 43px !important;
    border-radius: 30px !important;
    background: #004a91 !important;
  }
</style>
