<template>
    <v-row>
        <v-col xl="6"
               lg="6"
               md="6"
               sm="12"
               cols="12">
            <div class="single-service service__style--3 text-white" >
                <div class="">
                    <img data-aos="fade-right" data-aos-duration="1200" src="@/assets/images/blog/image-01.png" alt="Creative Agency" />
                </div>
            </div>
        </v-col>
       <v-col xl="5"
               lg="5"
               md="5"
               sm="12"
               cols="12">

            <div data-aos="fade-left" data-aos-duration="1200" class="single-service service__style--3 text-white">
                <h3 class="boldHeader">H ελκυστική κάρτα προνομίων της Αegean</h3>
                <p>Aποκτείστε την Aegean privilege card απο τα πρατήρια καυσίμων της Aegean, 
                συλλέξτε πόντους επιβράβευσης σε κάθε εφοδιασμό με καύσιμα Aegean και εξαργυρώστε τους κερδίζοντας δώρα ή / και επιστροφή χρημάτων!</p>
                <Register :fromHome = true />
            </div>
        </v-col>       
    </v-row>
</template>

<script>
  import Register from "@/views/Register";

  export default {
    name: 'serviceTwo',
    components: {
      Register,
    },
    data() {
      return {
      };
    },
  };
</script>

<style lang="scss">
  .button_beautify {
    padding: 30px 43px !important;
    border-radius: 30px !important;
    background: #004a91 !important;
  }
  .boldHeader{
    color: #222;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: -0.025rem;
    font-size: 1.425rem;
  }
  p {
      font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #333;
      font-weight: 300;
    }
</style>
