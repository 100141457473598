<template>
 <v-row>
       <v-col xl="5"
               lg="5"
               md="5"
               sm="12"
               cols="12">
            <div data-aos="fade-right" data-aos-duration="1200" class="single-service service__style--3 text-white">
                <h3 class="heading-title boldHeader">Η εταιρεία</h3><br>
                <p>
                  H Aegean Oil με τις ιδιόκτητες υπερσύνγχρονες εγκαταστάσεις της στην βόρεια και στη νότια Ελλάδα και με ένα πανελλαδικό δίκτυο 700 πρατηρίων
                  στο οποίο απασχολούνται συνολικά 2.200 εργαζόμενοι, είναι η πλέον δυναμικά αναπτυσσόμενη εταιρεία στο κλάδο της λιανικής εμπορίας καυσίμων και λιπαντικών.
                  Η προσφορά ποιοτικών προιόντων και υπηρεσιών είναι ο απώτερος και διαρκής στόχος της εταιρείας και αποτελεί τον πιο δυνατό σύνδεσμο στις σχέσεις με τους
                  συνεργάτες και καταναλωτές της ενώ οι ανταγωνιστικές τιμές αποτελούν κύριο μέλημα της εταιρείας.
                </p>
            </div>
        </v-col>  
        <v-col xl="1"
               lg="1"
               md="1"
               sm="12"
               cols="12">
        </v-col>  
         <v-col xl="6"
               lg="6"
               md="6"
               sm="12"
               cols="12">
            <div data-aos="fade-left" data-aos-duration="1200" class="single-service service__style--3 text-white" >
                 <img src="@/assets/images/blog/image_aegean.png" alt="Creative Agency" />
            </div>
        </v-col>     
    </v-row>
</template>

<script>

  export default {
    data() {
      return {
      };
    },
  };
</script>
<style scoped>
.boldHeader{
    color: #222;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: -0.025rem;
    font-size: 2.425rem;
  }
</style>