<template>
    <v-row >
        <v-col xl="4"
               lg="4"
               md="4"
               sm="12"
               cols="12">
            <div data-aos="fade-left" data-aos-duration="1200" class="single-service service__style--3 text-white mt--100 " >
                    <h3 class="heading-title">
                        <h3 class="boldHeader">Πρόγραμμα επιβράβευσης Aegean Privilege Card.</h3>
                    </h3>
                    <p>πόντοι, κατάλογος δώρων, πρατήρια Aegean είναι τώρα διαθέσιμα στο κινητό σας!</p>
                    <p>Κατεβάστε τώρα την εφαρμογή Aegean Privilege Card και κερδίστε 150 πόντους!</p>
            </div>
        </v-col>
        <v-col xl="4"
               lg="4"
               md="4"
               sm="12"
               cols="12">
                <img src="@/assets/images/logo/xarths2.png" alt="Creative Agency" />

        </v-col>
       <v-col xl="4"
               lg="4"
               md="4"
               sm="12"
               cols="12">
            <div  data-aos="fade-right" data-aos-duration="1200" class="single-service service__style--3 text-white mt--100">
              <div v-if="user === null">
                <h3 class="heading-title">
                    <h1 class="white-font">Aegean Privilege Card</h1>
                </h3>
              </div>
              <div v-else >
                <h3 class="heading-title">
                    <h1 class="white-font">Καλώς ήρθες {{ user.full_name }} </h1>
                </h3>
              </div>
                <div class="slider-btns mt--50">
                    
                  <v-row align="end" >
                        <v-col lg="6" md="6" sm="6" cols="6">
                            <a href="https://apps.apple.com/us/app/id1689983713" target="blank" class="app-btn1"><v-img src="@/assets/images/logo/appstore-tra-white.png" alt=""/></a>
                        </v-col>
                        <v-col lg="6" md="6" sm="6" cols="6">
                            <a href="https://play.google.com/store/apps/details?id=com.rcflavorproba" target="blank" class="app-btn2"><v-img src="@/assets/images/logo/googleplay-tra-white.png" alt=""/></a>

                        </v-col>
                    </v-row>
                  
                </div>
            </div>
        </v-col>       
    </v-row>
</template>

<script>
  import { mapMutations, mapActions, mapGetters } from "vuex";
  import Register from "@/views/Register";

  export default {
    name: 'user',
    components: {
      Register,
    },
    computed: {
      ...mapGetters (["user"]),
    },
    async  created () {
      this.getUser();

    },
     methods: {
      ...mapActions(["getUser"]),
    },
    data() {
      return {
      };
    },
  };
</script>

<style lang="scss" scoped>
    p {
      font-family: 'Roboto', 'Helvetica Neue', Helvetica, Arial, sans-serif;
      color: #333;
      font-weight: 300;
    }
  .button_beautify {
    padding: 30px 43px !important;
    border-radius: 30px !important;
    background: #004a91 !important;
  }
  .white-font {
    color: white !important;
    font-size: 2.625rem;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: -0.025rem;
  }
  .boldHeader{
    color: #222;
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    letter-spacing: -0.025rem;
    font-size: 1.425rem;
  }
</style>


